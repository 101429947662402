import { ModalContext } from '@/contexts/ModalContext'
import { useColorScheme } from '@/hooks/useColorScheme'
import { UpsellProps, useUpsell } from '@/hooks/useUpsell'
import { authenticatedApiFetch } from '@grandstand-web/bally-web-shared/src/apiClient/apiClient'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { GrandstandLogo } from '@grandstand-web/bally-web-shared/src/components/GrandstandLogo'
import { AnalyticServiceContext } from '@grandstand-web/bally-web-shared/src/services/analytics/AnalyticService'
import { ConfigServiceContext } from '@grandstand-web/bally-web-shared/src/services/config/ConfigService'
import { TealiumServiceContext } from '@grandstand-web/bally-web-shared/src/services/tealium/TealiumService'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { RegionTeam } from '@grandstand/presentation-models/types/primitives/team'
import { MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { LoadingSpinner } from '../../loading'
import { UpsellActions } from './UpsellActions'
import { UpsellForm } from './UpsellForm'
import { UpsellHeader } from './UpsellHeader'
import * as Styles from './styles'

export const UpsellModal = (props: UpsellProps) => {
  const { meta } = props
  const tealium = useContext(TealiumServiceContext)
  const analyticsService = useContext(AnalyticServiceContext)
  // contexts/hooks
  const { currentConfig } = useContext(ConfigServiceContext)
  const { currentUser, isInMarket } = useContext(UserServiceContext)
  const zipcode = currentUser?.profile.region.zip_code ?? '00000'
  const { closeModal, isOpen } = useContext(ModalContext)
  const { isDark } = useColorScheme()
  const { upsell } = useUpsell(props)

  // states
  const [availableTeams, setAvailableTeams] = useState<RegionTeam[]>([])

  const outOfMarket = useMemo(() => {
    return !isInMarket || !availableTeams.length
  }, [isInMarket, availableTeams])

  const loadingRef = useRef(false)
  const [ready, setReady] = useState(false)

  // make sure packages are up to date
  useEffect(() => {
    if (loadingRef.current || currentConfig === undefined || currentUser === undefined) {
      return
    }
    loadingRef.current = true
    const getRegion = async () => {
      const token = currentUser.user_token
      const url = `${currentConfig?.API.services.auth_services.region}/${zipcode}`
      const data = await authenticatedApiFetch({ url: url, method: 'GET', token: token })
      const region = await data.json()
      setAvailableTeams(region.teams) // interstitial should always show all logos in zipcode
      loadingRef.current = false
      setReady(true)
    }
    getRegion()
  }, [currentConfig, currentUser, isDark, meta?.entitlement_type, meta?.local_network_ids, zipcode])

  const region = currentUser?.profile?.region

  const handleClose = () => {
    tealium.upsellClose()
    closeModal()
  }
  const handleClickOutside: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === event.currentTarget) {
      handleClose()
    }
  }

  const onRequestClose = () => {
    handleClose()
  }

  // send analytics event
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!ready || !isOpen || zipcode === '00000') {
        return
      }
      analyticsService.upsellInterstitial({
        package_zipcode: zipcode,
        ecommerce: {
          items: [],
        },
      })
      tealium.upsellOpen()
    }, 250)
    return () => clearTimeout(timeout)
  }, [analyticsService, ready, isOpen, zipcode, tealium])
  if (!region) {
    return null
  }

  return (
    <Styles.Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Styles.Modal"
      style={{
        content: {
          position: 'static',
          inset: 'unset',
          justifySelf: 'flex-end',
          width: '100%',
          height: 'auto',
          maxHeight: 'calc(100% - 80px)',
          border: 'none',
          overflowY: 'unset',
          backgroundColor: 'transparent',
          borderRadius: '0',
          padding: '0',
        },
        overlay: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          width: '100vw',
          height: '100vh',
          zIndex: '1000000',
          overflow: 'unset',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    >
      <Styles.ModalStyles data-theme="light">
        <Styles.ModalGap padded={true} onClick={handleClickOutside} />
        <Styles.Wrapper onClick={handleClickOutside}>
          <Styles.Container padded={true}>
            <Styles.Grid>
              <Styles.MarketingBlock>
                <Styles.MarketingContainer padded={true} data-theme="dark">
                  <Styles.MarketingImage src="/lower-background.png" alt="" fill />
                  <Styles.MarketingContent>
                    <Styles.MarketingLabels>
                      <Styles.MarketingTitle fontSize="6">
                        {currentConfig?.API.text['upsell.left.header']}
                      </Styles.MarketingTitle>
                      <Styles.MarketingUL>
                        {currentConfig?.API.text['upsell.left.bullets'].map((bullet, key) => (
                          <Styles.MarketingLI key={key}>{bullet}</Styles.MarketingLI>
                        ))}
                      </Styles.MarketingUL>
                    </Styles.MarketingLabels>
                    <Styles.LogoWrapper>
                      <GrandstandLogo logoStyle="bally-plus-horiz" width="auto" />
                    </Styles.LogoWrapper>
                  </Styles.MarketingContent>
                </Styles.MarketingContainer>
              </Styles.MarketingBlock>
              <Styles.ContentBlock padded={true}>
                {/* show loading if teams not ready */}
                {!ready ? (
                  <>
                    <Styles.SpinnerStyles>
                      <LoadingSpinner />
                    </Styles.SpinnerStyles>
                  </>
                ) : (
                  <>
                    <UpsellHeader availableTeams={availableTeams} outOfMarket={outOfMarket} />
                    <UpsellForm />
                    <UpsellActions upsell={upsell} outOfMarket={outOfMarket} />
                  </>
                )}
              </Styles.ContentBlock>
            </Styles.Grid>
            <Styles.CloseRow>
              <Styles.CloseButton onClick={() => handleClose()} title="Close">
                <GrandstandIcon icon="close" />
              </Styles.CloseButton>
            </Styles.CloseRow>
          </Styles.Container>
        </Styles.Wrapper>
      </Styles.ModalStyles>
    </Styles.Modal>
  )
}
