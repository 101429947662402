import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { MetaEntitlementType } from '@grandstand/presentation-models/types/components/_abstract'
import { UserEntitlement } from '@grandstand/presentation-models/types/responses/profile'
import { useContext, useEffect, useState } from 'react'

export type EntitlementMeta = {
  local_network_ids?: string[]
  entitlement_type?: MetaEntitlementType
}

export enum UpsellMode {
  entitled = 'entitled',
  both = 'both',
  mvpd = 'mvpd'
}

export type UpsellState = {
  isEntitled: boolean | undefined
  mode: UpsellMode
  reason: string
  contentUrl: string
  meta: EntitlementMeta | undefined
}

export type UpsellStore = {
  upsell: UpsellState
}
export type UpsellProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
}
export const useUpsellLogic = ({ contentUrl = '/', meta }: UpsellProps) => {
  const { isDTC, isMVPD, isLoggedIn, currentUser } = useContext(UserServiceContext)
  const [upsell, setUpsell] = useState<UpsellState>({
    contentUrl,
    meta,
    isEntitled: true,
    reason: 'Initial upsell upsell',
    mode: UpsellMode.entitled
  })
  useEffect(() => {
    const contentIds: string[] = meta?.local_network_ids ?? []
    const contentType: MetaEntitlementType | undefined = meta?.entitlement_type
    const mode = contentType === 'mvpd' ? UpsellMode.mvpd : UpsellMode.both
    const handleState = (isEntitled: boolean, reason: string) => {
      setUpsell((prev) => ({
        meta: prev.meta,
        contentUrl: prev.contentUrl,
        isEntitled,
        mode,
        reason
      }))
      return
    }

    if (currentUser === undefined) {
      return handleState(false, 'Current user is not entitled because currentUser is missing')
    }
    const userEntitlements: UserEntitlement[] = currentUser.profile.entitlements

    if (meta === undefined) {
      return handleState(true, 'User is entitled because content meta is missing')
    }

    if (contentIds.length === 0) {
      return handleState(true, 'User is entitled because content meta has no local network ids')
    }

    if (contentType === 'free') {
      return handleState(true, 'User is entitled because content entitlement type is Free')
    }

    const getContentIsInUserRegion = (): boolean => {
      if (contentIds?.length === 0) {
        // Content is free for all
        return true
      }
      if (userEntitlements?.length <= 0) {
        return false
      }
      const results = userEntitlements.filter((value) => {
        const typeMatched = contentType === 'mvpd' ? value.type === 'mvpd' : true
        if (!typeMatched) {
          return false
        }
        return userEntitlements.some((value) => {
          const areas = value.local_network_ids
          return areas.some((area) => contentIds.includes(area))
        })
      })
      return results.length > 0
    }
    const entitlementsString = JSON.stringify(userEntitlements)
    if (getContentIsInUserRegion()) {
      return handleState(true, `User entitled to ${contentType} content with entitlements = ${entitlementsString}`)
    } else {
      return handleState(
        false,
        `User not entitled to ${contentType} content with entitlements = [ ${userEntitlements.join(', ')} ]`
      )
    }
  }, [currentUser, meta?.local_network_ids, meta?.entitlement_type, isLoggedIn, isDTC, isMVPD, meta])

  return {
    upsell
  }
}
